<script lang="ts">
  import { getContext, setContext } from 'svelte'
  import { writable } from 'svelte/store'

  import FieldsetLabel from '../../../../style/style-fieldset-label.svelte'
  import Filters from './search-pane-atoms/filter/filters.svelte'
  import CardView from './search-pane-atoms/card-view/card-view.svelte'

  import { state } from '../../../../store/state'
  import { FilterManager } from '../../../content/filter/filter-manager'

  import { RIGHT_PANEL_CONTEXT } from '../right-panel'

  import { SEARCH_PANE_CONTEXT, filterManagers, setKind } from './search-pane'
  import { searchPaneContext } from './search-pane-atoms/filter/kind-filter'

  const { open } = getContext(RIGHT_PANEL_CONTEXT) as any

  const { fm: allFilterManagers, defaultNameOnState } = filterManagers(state)

  let activeFilterManager = writable<FilterManager>(allFilterManagers[defaultNameOnState])
  let activeContentKind = writable(allFilterManagers[defaultNameOnState].contentKind)
  let firstOpened = false

  $searchPaneContext = {
    filterManager: activeFilterManager,
    contentKind: activeContentKind,
  }

  setContext(SEARCH_PANE_CONTEXT, {
    activeFilterManager,
    activeContentKind,
    setKind: async (kind: string) => {
      if ($open) {
        await setKind(kind, allFilterManagers, activeFilterManager, activeContentKind)
      }
    },
  })

  $: filterManager = $activeFilterManager
  $: {
    if ($open && !firstOpened) {
      firstOpened = true
      setKind(defaultNameOnState, allFilterManagers, activeFilterManager, activeContentKind)
    }
  }

  $$props
</script>

<div class="search-pane flex flex-col gap-6 w-full">
  <div class="px-4">
    <FieldsetLabel>
      <Filters {filterManager} />
    </FieldsetLabel>
  </div>
  <CardView />
</div>

<style lang="postcss">
  .search-pane {
    height: calc(100% - 4rem);
  }
</style>
