import { type WritableStore, atom } from 'nanostores'
import { server as factory } from '../../../../../server'
import { type ClientApplication as ServerStub } from 'server/src/client'
import type { FactResultItem, FactiverseQuery } from 'common/src/types'
import { reportWarning } from '../../../../../store/notifications'
import { State } from '../../../../../store/state'
import { factLanguage } from './fact-checking-lang'

export enum FactDateRange {
  ONE_DAY,
  THREE_DAYS,
  ONE_WEEK,
  CUSTOM,
  ANY
}

export type FactDateRangeValue = {
  from: string
  to: string
}

export const dateRangeBtnData = [
  {
    topLabel: 1,
    bottomLabel: 'Day',
    enumValue: FactDateRange.ONE_DAY,
  },
  {
    topLabel: 3,
    bottomLabel: 'Days',
    enumValue: FactDateRange.THREE_DAYS,
  },
  {
    topLabel: 1,
    bottomLabel: 'Week',
    enumValue: FactDateRange.ONE_WEEK,
  },
  {
    topLabel: 'Custom',
    bottomLabel: 'Custom',
    enumValue: FactDateRange.CUSTOM,
  },
  {
    topLabel: 'Any',
    bottomLabel: 'Any',
    enumValue: FactDateRange.ANY,
  },
]

export type FactLanguage = {
  id: number
  code: string
  text: string
}

export const dateToString = (date: Date): string => date.toISOString().split('T')[0]

export class FactCheckingService {
  dateRange: WritableStore<FactDateRangeValue | null> = atom(null)
  actualDateRange: WritableStore<FactDateRange> = atom(FactDateRange.ONE_WEEK)
  customDateVisible: WritableStore<boolean> = atom(false)
  selectedLanguage: WritableStore<number> = atom(1)
  inputValue: WritableStore<string> = atom('')
  loading: WritableStore<boolean> = atom(false)
  searchResult: WritableStore<FactResultItem[]> = atom([])

  constructor(
    private state: State,
    private server: () => Promise<ServerStub> = factory
  ) {
    this.setDateRange(FactDateRange.ONE_WEEK)
  }

  setDateRange(rangeType: FactDateRange, range?: FactDateRangeValue): FactDateRangeValue | undefined {
    if (rangeType !== FactDateRange.CUSTOM) {
      this.actualDateRange.set(rangeType)
      this.customDateVisible.set(false)
    }

    const now = new Date()
    const from: Date = new Date()
    const to: Date = new Date()

    switch (rangeType) {
      case FactDateRange.ONE_DAY:
        from.setDate(now.getDate() - 1)
        break
      case FactDateRange.THREE_DAYS:
        from.setDate(now.getDate() - 3)
        break
      case FactDateRange.ONE_WEEK:
        from.setDate(now.getDate() - 7)
        break
      case FactDateRange.ANY:
        this.dateRange.set(null)
        return
      case FactDateRange.CUSTOM:
        if (!range) {
          this.customDateVisible.set(!this.customDateVisible.get())
          return
        }

        this.dateRange.set(range)
        this.actualDateRange.set(rangeType)
        return
    }

    this.dateRange.set({
      from: dateToString(from),
      to: dateToString(to),
    })
  }

  async doSearch() {
    if (
      !this.inputValue.get() ||
      (!this.dateRange.get() && this.actualDateRange.get() !== FactDateRange.ANY)
    ) {
      return
    }

    this.loading.set(true)

    const query = this.buildQuery()

    const instance = await this.server()

    const result = await instance.service('factiverse').claim(query)

    if (result.warning) {
      reportWarning(result.warning, this.state)
      this.searchResult.set([])
      this.loading.set(false)
      return
    }

    this.searchResult.set(result)
    this.loading.set(false)
  }

  buildQuery() {
    if (!this.dateRange.get() && this.actualDateRange.get() !== FactDateRange.ANY) {
      return
    }

    const searchLang = factLanguage.find((item: FactLanguage) => this.selectedLanguage.get() === item.id)

    if (!searchLang) {
      return
    }

    const searchQuery: FactiverseQuery = {
      reverseSortPubDate: true,
      lang: searchLang.code,
      logging: true,
      query: this.inputValue.get(),
      searchEngine: ['fact_search_elasticsearch'],
    }

    if (this.actualDateRange.get() !== FactDateRange.ANY) {
      const { from, to } = this.dateRange.get() as FactDateRangeValue

      searchQuery.fromDate = from
      searchQuery.toDate = to
    }

    return searchQuery
  }
}
