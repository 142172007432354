import { Writable, writable } from 'svelte/store'
import { atom } from 'nanostores'

import { State } from '../../../../../../store/state'
import { type ContentKind } from '../../../../../../store/content-kind'
import { type FilterManager } from '../../../../../content/filter/filter-manager'

import { DEFAULT_SELECTED_KIND } from '../../../right-panel'
import { removePrefix } from 'common/src/content'

const allNameOnState: string[] = []

export const filter = (state: State) => {
  allNameOnState.length = 0
  allNameOnState.push(
    ...state.searchContents
      .map((c: ContentKind<any>) => removePrefix(c.kind.nameOnState, 'search').toLowerCase())
      .sort()
  )
  const items = writable(allNameOnState.map((kind: string, index: number) => ({ id: index, text: kind })))
  const selected = writable(kindAsId(DEFAULT_SELECTED_KIND))

  return {
    items,
    selected,
  }
}

export const kindAsString = (id: number) => allNameOnState[id]
export const kindAsId = (kind: string) => allNameOnState.indexOf(kind)

export const searchPaneContext = atom<{
  contentKind: Writable<ContentKind<any>> | undefined
  filterManager: Writable<FilterManager> | undefined
}>({
  contentKind: undefined,
  filterManager: undefined,
})
